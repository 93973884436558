import React from 'react'
import './feature.css'
import iconOne from '../../assets/iconOne.png'
import iconTwo from '../../assets/iconTwo.png'
import iconThree from '../../assets/iconThree.png'
import iconFour from '../../assets/iconFour.png'
import iconFive from '../../assets/iconFive.png'
import iconSix from '../../assets/iconSix.png'
// import iconFour from '../../assets/iconFour.png'
function Feature() {
  return (
    <div className='feature'>
        <div className='feature-title'>
        Major Features
        </div>
        <div className='feature-text'>Adera Delivery is designed to make your life easier. 
        We offer reliable service with fast and easy delivery service <br/>Adera is the perfect solution for all your needs.</div>
        <div className="wrap-feature">
          <div className='feature-section'>
            <div className="icons-feature"><img src={iconOne}/></div>
            <p className='title'>Robust workflow</p>
            <p className='text'>Streamline and optimize your productivity with our robust workflow application, designed to handle single processes with ease and efficiency.</p>
        </div>
        <div className='feature-section'>
            <div className="icons-feature"><img src={iconTwo}/></div>
            <p className='title'>Flexibility</p>
            <p className='text'>Effortlessly adapt to changing customer needs with our flexible delivery application, enabling you to provide a range of customizable delivery options and streamline the entire delivery process.</p>
        </div>
        <div className='feature-section'>
            <div className="icons-feature"><img src={iconThree}/></div>
            <p className='title'>User Friendly</p>
            <p className='text'>Our user-friendly application offers an intuitive interface and easy-to-use features, ensuring a seamless experience for all users, regardless of their technical expertise.</p>
        </div>
        <div className='feature-section'>
            <div className="icons-feature"><img src={iconFour}/></div>
            <p className='title'>Multilingual</p>
            <p className='text'>Break down language barriers and reach a wider audience with our multilingual application, providing seamless translation and localization features for users in multiple languages.</p>
        </div>
        <div className='feature-section'>
            <div className="icons-feature"><img src={iconFive}/></div>
            <p className='title'>Real-time Tracking</p>
            <p className='text'>Track your deliveries in real-time and provide your customers with accurate updates on their order status with our real-time tracking feature, enabling you to optimize your delivery routes and ensure timely deliveries.</p>
        </div>
        <div className='feature-section'>
            <div className="icons-feature"><img src={iconSix}/></div>
            <p className='title'>Proof of Delivery</p>
            <p className='text'>Ensure accountability and prevent disputes with our proof of delivery feature, allowing drivers to capture signatures, photos, and notes from customers upon delivery, providing you with a digital record of delivery confirmation.</p>
        </div>
        </div>
    </div>
  )
}

export default Feature