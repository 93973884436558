import React from "react";
import About from "../About/About";
import Feature from "../Features/Feature";
import Testimonial from "../Testimonial/Testimonial";
import Home from "../Home/Home";
// import About from "./components/About/About";
// import Feature from "./components/Features/Feature";
// import Footer from "./components/Footer/Footer";
// import Home from "./components/Home/Home";
// import Testimonial from "./components/Testimonial/Testimonial";
const Main = () => {
  return (
    <div>
      <div id="Home">
        <Home />
      </div>
      <div id="Feature">
        <Feature />
      </div>
      <div id="Testimonial">
        <Testimonial />
      </div>
      <div id="About">
        <About />
      </div>
    </div>
  );
};

export default Main;
