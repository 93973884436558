import React from 'react'
import homeImage from '../../assets/home-image.png'
import background from '../../assets/Background.png'
import Icon from '../../assets/Icon.png'
import './home.css'
import Nav from '../Nav/Nav'
function Home() {
  return (
    <div className='home-body'>
      <Nav/>
        <div
        className="Home-section"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          display: "flex",
        }}
      >
        <div className='section-one'>
        <div className='home-title'>
        Fast, easy, reliable delivery.
        </div>
        <div className='home-text'>
        Adera delivery makes your day to day activity easy to get what you need, when you need it. With fast and reliable service, Adera is the app you can put your trust on more with just a few taps on your phone. 
        </div>
        <div className='home-buttons'>
        <a href='#' className='decoration'><div className='home-button'><p className='btn-text'>Get started</p></div></a>
        {/* <a href='#' className='decoration'><div className=''><p className='btn-text'><img src={Icon} className="icon"/>Watch Video</p></div></a> */}
        </div>
        </div>

        <div className='section-two'>
            <img src={homeImage} className="home-image"/>
        </div>
      </div>
        
    </div>
  )
}

export default Home