import React from "react";
import "./footer.css";
import logo from "../../assets/logo.png";
import playStore from "../../assets/playStore.png";
import appStore from "../../assets/appStore.png";
function Footer() {
  return (
    <div>
      <div className="footer">
        <div className="footer-section">
          <div>
            <img src={logo} />
            <div className="link-footer">
              <a href="" className="decoration">
                <div className="footer-links">Download Now</div>
              </a>
              <a href="" className="decoration">
                <div className="footer-links">Licence</div>
              </a>
            </div>
            <div className="linkFooterTwo">
              <a href="/#About">
                <div className="footer-links">About</div>
              </a>
              <a href="/#Feature">
                {" "}
                <div className="footer-links">Features</div>
              </a>
              {/* <div className='footer-links'>Pricing</div>
            <div className='footer-links'>Careers</div>
            <div className='footer-links'>Help</div> */}
              <div className="footer-links">
                {" "}
                <a href="/privacy">Privacy Policy</a>{" "}
              </div>
            </div>
          </div>
          <div>
            <p className="footer-links">Get the App</p>
            <img src={appStore} />
            <br />
            <img src={playStore} className="playstore" />
          </div>{" "}
        </div>
        <div className="Bitapps__container">
          <a href="#">
            <h4>
              {" "}
              © 2023 | Designed and developed by{" "}
              <span className="Span">
                <a href="https://bitappstech.com/">bitappstech</a>{" "}
              </span>{" "}
            </h4>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
