import React from "react";
import "./Privacy.css";
import Nav from "../Nav/Nav";
const Privacy = () => {
  return (
    <div>
      <Nav />
      <section>
        <div className="Privacy__container">
          <h1> Privacy Policy</h1>
          <h3>1 Introduction</h3>
          <h2>
            Adera, We respects the privacy of its users and is committed to
            protecting your personal information. This Privacy Policy outlines
            how we collect, use, and disclose your personal information when you
            use our Adera Delivery mobile/web application and related services.
            By using the Service, you agree to the collection and use of your
            personal information in accordance with this Privacy Policy.
          </h2>
          <h3>2 Information We Collect</h3>
          <div className="Sub__container">
            <h2>
              We may collect the following types of information from you when
              you use the Service:
              <br />
              <br />
              <span> a.Personal Information</span>: This includes information
              such as your name, email address, phone number, and other
              information that can be used to identify you.
              <br />
              <span>b.Usage Information</span> : This includes information about
              how you use the Service, such as the pages you visit, the time and
              date of your visits, and other usage data.
              <br />
              <span> c. Device Information:</span> This includes information
              about the device you use to access the Service, such as the
              device's model, operating system, and other technical information.
              <br />
              <span> d. Location Information:</span> This includes information
              about your location when you use the Service, which may be
              collected through GPS, Wi-Fi, or other methods.
            </h2>
          </div>
          {/* ===========================Third================================== */}
          <div>
            <h3>3 How We Use Your Information</h3>
            <div className="Sub__container">
              <h2>
                We may use your personal information for various purposes,
                including but not limited to:
                <br />
                <br />
                <span> a.</span> Providing and improving the Service.:
                <br /> <span> b.</span> Communicating with you about the
                Service, including updates, promotions, and support.:
                <br /> <span> c.</span> Personalizing your experience with the
                Service.:
                <br /> <span> d.</span> Analyzing usage patterns and trends to
                improve the Service and develop new features. :<br />
                <span> e.</span> Preventing fraud, enforcing our terms, and
                ensuring the security of the Service.
              </h2>
            </div>
          </div>
          {/* ===========================fourt================================== */}
          <div>
            <h3>4 Disclosure of Your Information</h3>
            <div className="Sub__container">
              <h2>
                We may share your personal information with third parties in
                certain circumstances, including but not limited to:
                <br />
                <br />
                <span> a. Service Providers:</span> We may share your personal
                information with third parties who perform services on our
                behalf, such as payment processing, analytics, and customer
                support.
                <br />
                <span>b . Legal Requirements :</span> We may disclose your
                personal information if required to do so by law or in response
                to a legal process, such as a subpoena or court order.
                <br />
                <span> c . Business Transfers:</span> In the event of a merger,
                acquisition, or other business transaction involving the
                Company, your personal information may be transferred to the new
                owner.
                <br />
              </h2>
            </div>
          </div>
          {/* ===========================Five================================== */}
          <div>
            <h3>5 Security</h3>
            <h2>
              We take reasonable precautions to protect your personal
              information from unauthorized access, use, or disclosure. However,
              no method of transmission or storage is completely secure, and we
              cannot guarantee the absolute security of your personal
              information.
            </h2>
          </div>
          {/* ===========================six================================== */}
          <div>
            <h3>6 Children's Privacy</h3>
            <h2>
              The Service is not intended for use by children under the age of
              18. We do not knowingly collect personal information from children
              under 18. If you are a parent or guardian and believe your child
              has provided us with personal information, please contact us at
              +251 90 946 5857 | +251 91 099 0147.
            </h2>
          </div>
          {/* ===========================seven================================== */}
          <div>
            <h3>7 International Transfers</h3>
            <h2>
              Your personal information may be transferred to and processed in
              other countries where data protection laws may be different from
              those in your country. By using the Service, you consent to the
              transfer, processing, and storage of your personal information in
              such countries.
            </h2>
          </div>
          {/* ===========================Eight================================== */}
          <div>
            <h3>8 Changes to This Privacy Policy</h3>
            <h2>
              We reserve the right to update or modify this Privacy Policy at
              any time. Any changes will be effective upon posting, and your
              continued use of the Service constitutes your acceptance of any
              updated Privacy Policy.
            </h2>
          </div>
          {/* ===========================nine================================== */}
          <div>
            <h3>9 Contact Us</h3>
            <h2>
              If you have any questions or concerns about this Privacy Policy or
              our privacy practices, please contact us at +251 90 946 5857 |
              +251 91 099 0147
            </h2>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Privacy;
