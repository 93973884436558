import React from 'react'
import './testimonial.css'
import testimonial from '../../assets/testimonial.png'
import test from '../../assets/test.png'
function Testimonial() {
  return (
    <div className='Testimonial'>
      <div className='real'>
      <div><img src={testimonial} className="testimonial-symbol"/></div>
        <div>
        <p className='testimonial-title'>Introducing Adera, <br/>
        your ultimate delivery app!</p>
        <p className='testimonial-text'>Are you tired of waiting in long lines or spending hours on traffic just to get your essentials?</p>
        </div>
      
      </div>
     
        <div className='wrap-testimonials'>
        <div className='testimonial-section background'>  
        <img src={test} className="symbol"/> 
        <p className='test-name'>Easy ordering process</p>
<p className='test-text'>  - Our app is user-friendly and straightforward, allowing you to easily send items you need.</p>
  
   {/* <p className='test-position'>Vice President, GoPro</p> */}
</div>
<div className='wrap-sections'>
<div className='testimonial-sectionOne background'>
<div><img src={test} className="symbol"/></div>
<p className='test-name'>Fast delivery</p>
<p className='test-text'> - We know how important time is, that's why we strive to deliver your items as quickly as possible. Our average delivery time is less than an hour</p>
 
   {/* <p className='test-position'>CEO, Airbnb</p> */}
        </div>
        <div className='testimonial-sectionTwo background'>
        <img src={test} className="symbol"/>
        <p className='test-name'> Secure and reliable</p>
<p className='test-text'>- Our app uses the latest security technology to ensure that your personal information and payment details are safe and secure.</p>
  
   {/* <p className='test-position'>Co-Founder, Strapi</p> */}
        </div>
       
</div>

        </div>
     
    </div>
  )
}

export default Testimonial